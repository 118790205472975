import Link from "next/link";
import React from "react";

declare const $crisp: any;

type ContactButtonProps = {
  title: string;
  text: string;
  onClick?: () => void;
};

const ContactButton: React.FC<ContactButtonProps> = ({
  title,
  text,
  onClick,
}) => {
  return (
    <button
      type="button"
      title={title}
      className="w-full text-center block mt-3 col-span-2 items-center px-2 py-1 border border-transparent text-lg font-semibold rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
      onClick={() => {
        $crisp?.push(["do", "chat:open"]);
      }}
    >
      {text}
    </button>
  );
};

export { ContactButton };
