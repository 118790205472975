import React from "react";
import { parseISO } from "date-fns";
import { Container } from "../components/container";
import { CustomerReview } from "../components/customer-review";
import { gql, useQuery } from "@apollo/client";
import { getTopReviews } from "../__generated__/getTopReviews";
import Link from "next/link";

type TestimonialProps = {};

const GET_TOP_RATINGS = gql`
  query getTopReviews {
    topReviews {
      id
      rating
      gender
      contentClass
      review
      firstname
      lastname
      createdAt
    }
  }
`;

const TopReviews: React.FC<TestimonialProps> = ({}) => {
  const { data } = useQuery<getTopReviews>(GET_TOP_RATINGS, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  return (
    <div>
      <Container containerClassName="bg-gray-50" maxWidth="1200px">
        <h2 className="text-2xl md:text-4xl font-semibold text-green-400">
          Das sagen unsere zufriedenen Kunden über uns
        </h2>
      </Container>
      <Container containerClassName="bg-green-400 py-8" maxWidth="1200px">
        <div className="flex flex-col lg:flex-row">
          {(data?.topReviews ?? []).map(review => {
            const anonym = !review.firstname && !review.lastname;
            const name = review.firstname + " " + (review.lastname ?? "");
            const usedName = anonym ? "Anonym" : name;

            return (
              <div
                key={review.id}
                className="lg:w-1/2 flex lg:pr-6 my-5 lg:my-0 text-white"
              >
                <CustomerReview
                  rating={review.rating}
                  gender={review.gender}
                  contentClass={review.contentClass}
                  review={review.review}
                  name={usedName}
                  createdAt={parseISO(review.createdAt)}
                  green
                />
              </div>
            );
          })}
        </div>
        <Link href="/bewertungen/">
          <a className="block md:mt-8 mx-auto mt-2 text-white border md:border-none border-white font-semibold text-center rounded px-2 py-1 text-lg w-full bg-green-400">
            Alle Bewertungen
          </a>
        </Link>
      </Container>
    </div>
  );
};

export { TopReviews };
