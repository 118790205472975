// If you don't want to use TypeScript you can delete this file!
import React from "react";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar as filledStar,
  faDuck,
  faCrow,
  faRabbit,
  faFish,
  faBug,
  faTurtle,
} from "@fortawesome/pro-solid-svg-icons";
import { faStar as emptyStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { ContentClass, Gender } from "../__generated__/globalTypes";
import { formatDate } from "../lib/util";
import { useRouter } from "next/router";


const classToIcon: { [contentClass in ContentClass]: any } = {
  [ContentClass.POULTRY]: faDuck,
  [ContentClass.BIRDS]: faCrow,
  [ContentClass.MAMMALS]: faRabbit,
  [ContentClass.FISH]: faFish,
  [ContentClass.INSECTS]: faBug,
  [ContentClass.REPTILES]: faTurtle,
};

type DataProps = {
  rating: number;
  gender: Gender;
  contentClass: ContentClass;
  review: string;
  name: string;
  createdAt: Date;
  green?: boolean;
};

const CustomerReview: React.FC<DataProps> = ({
  rating,
  name,
  gender,
  contentClass,
  review,
  createdAt,
  green,
}) => {
  const imgNum = Math.floor(name.charCodeAt(0) % 4) + 1;
  const imgGender = gender === Gender.MALE ? "male" : "female";
  const image = `/img/avatar/${imgGender}_${imgNum}.png`;
  const {locale, defaultLocale} = useRouter();

  return (
    <div className="w-full">
      <div className="flex">
        <div className="mb-1 md:mb-0 flex-shrink-0 pr-2">
          <Image
            src={image}
            width="64rem"
            height="64rem"
            className="object-cover rounded-full"
          />
        </div>
        <div className="w-full">
          <div className="md:flex justify-between">
            <div className="flex">
              {new Array(5).fill(1).map((n, i) => (
                <FontAwesomeIcon
                  key={i}
                  icon={rating > i ? filledStar : emptyStar}
                  className="text-xl text-yellow-300 w-5"
                />
              ))}
              <FontAwesomeIcon
                icon={classToIcon[contentClass]}
                className="ml-2 text-xl w-5"
              />
            </div>
            <div
              className={`mt-2 text-sm md:hidden ${
                green ? "text-white" : "text-gray-500 "
              }`}
            >
              {name}
            </div>
            <div
              className={`text-xs ${green ? "text-white" : "text-gray-500 "}`}
            >
              {formatDate(createdAt, {locale, defaultLocale})}
            </div>
          </div>
          <div className="mt-2 hidden md:block">
            <div className="my-1 whitespace-pre-wrap">{review}</div>
            <div
              className={`text-sm ${green ? "text-white" : "text-gray-500 "}`}
            >
              {name}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 md:hidden">
        <div className="my-1 whitespace-pre-wrap">{review}</div>
        <div
          className={`text-sm hidden md:block ${
            green ? "text-white" : "text-gray-500 "
          }`}
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export { CustomerReview };
