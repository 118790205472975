import React, { useState } from "react";
import { Router, useRouter } from "next/router";
import { useShipment } from "../lib/booking";
import { useQuery } from "@apollo/client";
import { formatMoney, GET_PRICE } from "../lib/util";
import { getPrice, getPriceVariables } from "../__generated__/getPrice";
import { useDebounce } from "react-use";
import Link from "next/link";

interface HeroBookPriceProps {
  onReset: () => void;
}

const HeroBookPrice: React.FC<HeroBookPriceProps> = ({ onReset }) => {
  const router = useRouter();
  const { shipment, setInitialInfo } = useShipment();

  const { data, loading } = useQuery<getPrice, getPriceVariables>(GET_PRICE, {
    fetchPolicy: "cache-first",
    errorPolicy: "all",
    variables: {
      input: shipment as any,
    },
  });

  const [debouncedLoading, setDebouncedLoading] = useState(false);
  useDebounce(
    () => {
      setDebouncedLoading(loading);
    },
    150,
    [loading]
  );

  const price = data?.calculatePrice?.total;

  if (!loading && !data?.calculatePrice) {
    return (
      <div>
        <div className="text-xl text-center font-medium p-3 rounded">
          Auf dieser Route können wir ihr Tier aktuell leider nicht versenden
        </div>
        <button
          className="tracking-wide shadow-lg letter w-full mt-3 col-span-2 items-center px-2 py-2 border border-transparent text-2xl md:text-xl font-semibold rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          onClick={onReset}
        >
          Zurück
        </button>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl text-center font-semibold">
        Ihr Tier sicher zu versenden kostet
      </h2>
      <div className="pb-8 pt-5 text-5xl text-center font-bold text-green-400 leading-tight">
        <div className="text-xl">ab </div>
        {debouncedLoading && loading ? (
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <div>{price !== undefined && formatMoney(price, router.locale)}</div>
        )}
        <div className="text-center text-sm text-green-400">
          inkl. Mehrwertsteuer
        </div>
      </div>
      {!debouncedLoading && (<Link href="/booking/parcel?parcelnum=0">
        
          <button className="tracking-wide shadow-lg letter w-full mt-3 col-span-2 items-center px-2 py-2 border border-transparent text-2xl md:text-xl font-semibold rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
            Weiter
          </button>
      </Link> )}
    </div>
  );
};
export { HeroBookPrice };
