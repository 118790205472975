import React, { useState } from "react";
import Head from "next/head";
import { Container } from "../components/container";
import { HeroBookEnterData } from "./hero-book-enter-data";
import { HeroBookPrice } from "./hero-book-price";
import { ContentClass } from "../__generated__/globalTypes";

interface HeroBookProps {
  h1?: string;
  bgImage: string;
  defaultContentClass?: ContentClass;
}

const HeroBook: React.FC<HeroBookProps> = ({ h1, bgImage, defaultContentClass }) => {
  const [showPrice, setShowPrice] = useState(false);

  return (
    <Container
      maxWidth="1200px"
      style={{ backgroundPosition: "center", backgroundSize: "cover" }}
      containerClassName="hero-image"
    >
      <Head>
        <style>
          {`
          @media only screen and (max-width: 600px) {
            .hero-image {
              background-image: url(/img/hero/${bgImage}-xs.jpg);
            }
          }
          @media only screen and (min-width: 601px) and (max-width: 800px) {
            .hero-image {
              background-image: url(/img/hero/${bgImage}-sm.jpg);
            }
          }
          @media only screen and (min-width: 801px) {
            .hero-image {
              background-image: url(/img/hero/${bgImage}.jpg);
            }
          }
          `}
        </style>
      </Head>
      <div className="flex flex-col flex-wrap md:flex-no-wrap justify-between mt-24 md:mt-40 mb-24 md:mb-32">
        <div className="w-full md:w-1/2">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-semibold leading-none text-white text-shadow-lg">
            {h1 ?? "Wir versenden Ihre Tiere"}
          </h2>
          <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold leading-tight text-white text-shadow-lg mt-2">
            Schnell, Sicher und Zuverlässig
          </h3>
        </div>
        <div
          className="w-full sm:w-96 p-4 rounded shadow-lg mt-4"
          style={{ backgroundColor: "rgba(255,255,255, .85)" }}
        >
          {showPrice ? (
            <HeroBookPrice onReset={() => setShowPrice(false)} />
          ) : (
            <HeroBookEnterData onCalculatePrice={() => setShowPrice(true)} defaultContentClass={defaultContentClass} />
          )}
        </div>
      </div>
    </Container>
  );
};
export { HeroBook };
