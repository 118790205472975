import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";

type AdvantagesProps = {};

const Advantages: React.FC<AdvantagesProps> = ({}) => {
  return (
    <div className="bg-green-400 text-white flex justify-center">
      <div className="sm:flex flex-no-wrap flex-row justify-center items-center sm:space-x-4 md:space-x-8 text-lg md:text-xl lg:text-2xl xl:text-3xl py-3 md:py-6">
        <div className="flex items-center space-x-1">
          <FontAwesomeIcon icon={faCheckCircle} className="w-6 h-6 md:w-8 md:h-8" />
          <span>24h Expressversand</span>
        </div>
        <div className="flex items-center space-x-1">
          <FontAwesomeIcon icon={faCheckCircle} className="w-6 h-6 md:w-8 md:h-8" />
          <span>Schonender Transport</span>
        </div>
        <div className="flex items-center space-x-1">
          <FontAwesomeIcon icon={faCheckCircle} className="w-6 h-6 md:w-8 md:h-8" />
          <span>Von Haustür zu Haustür</span>
        </div>
      </div>
    </div>
  );
};

export default Advantages;