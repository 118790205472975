import React from "react";
import { Container } from "../components/container";
import { ContactButton } from "../components/contact-button";
declare const $crisp: any;
interface BusinessOfferProps {}

const BusinessOffer: React.FC<BusinessOfferProps> = ({}) => {
  return (
    <Container containerClassName="py-8" maxWidth="1000px">
      <div className="md:py-16 flex flex-col lg:flex-row items-center lg:items-stretch text-md md:text-lg">
        <h2
          className="w-full lg:w-1/3 text-2xl lg:text-5xl leading-tight text-green-400 block lg:hidden"
          style={{ maxWidth: 500 }}
        >
          Vielversender Konditionen
        </h2>
        <h2
          className="lg:w-1/3 text-3xl lg:text-5xl leading-tight text-green-400 hidden lg:block"
          style={{ maxWidth: 700 }}
        >
          Vielversender
          <br />
          Konditionen
        </h2>
        <div className="w-full lg:w-3/5" style={{ maxWidth: 500 }}>
          <p className="mt-2 lg:mt-3">
            Für <strong>Züchter</strong>, <strong>Vereine</strong>,{" "}
            <strong>Händler</strong> bieten wir spezielle
            Vielversender-Kondtionen für den Tierversand.
          </p>
          <p className="mt-2 lg:mt-3">
            Bereits ab einem Volumen von 10 Sendungen pro Monat können Sie so
            noch günstiger schnell, sicher und zuverlässig Tiere mit uns
            versenden.
          </p>
          <ContactButton 
            onClick={() => {
              $crisp?.push(["do", "chat:open"]);
            }}
            title="Kontakt Aufnehmen"
            text="Kontakt Aufnehmen"
          />
        </div>
      </div>
    </Container>
  );
};

export { BusinessOffer };
